// Generated by Framer (8563fcd)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {D79cps3G_: {hover: true}};

const cycleOrder = ["D79cps3G_"];

const variantClassNames = {D79cps3G_: "framer-v-12izao7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "D79cps3G_", title: bUUso4DP4 = "Contact Us", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "D79cps3G_", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-8qsjt", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.button {...restProps} className={cx("framer-12izao7", className)} data-framer-name={"Variant 1"} data-reset={"button"} layoutDependency={layoutDependency} layoutId={"D79cps3G_"} ref={ref} style={{backgroundColor: "var(--token-69d0d919-5106-45a3-9073-8a95b5ccacb9, rgb(255, 83, 0))", borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5, ...style}} variants={{"D79cps3G_-hover": {backgroundColor: "rgb(227, 227, 227)"}}} {...addPropertyOverrides({"D79cps3G_-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7Q2xhc2ggRGlzcGxheS1tZWRpdW0=", "--framer-font-family": "\"Clash Display\", sans-serif", "--framer-font-size": "21px", "--framer-font-weight": "500", "--framer-letter-spacing": "0.52px", "--framer-line-height": "1.4em", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Contact Us</motion.p></React.Fragment>} className={"framer-1tesjdi"} data-framer-name={"Contact Us"} fonts={["FS;Clash Display-medium"]} layoutDependency={layoutDependency} layoutId={"WiVjPCdhd"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={bUUso4DP4} variants={{"D79cps3G_-hover": {"--extracted-r6o4lv": "var(--token-883132e0-45d6-4b2b-bb30-29dc404e311e, rgb(29, 29, 31))"}}} verticalAlignment={"top"} withExternalLayout/></motion.button></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-8qsjt [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8qsjt .framer-dybin4 { display: block; }", ".framer-8qsjt .framer-12izao7 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 8px 16px 8px 16px; position: relative; width: min-content; }", ".framer-8qsjt .framer-1tesjdi { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-8qsjt .framer-v-12izao7 .framer-12izao7 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-8qsjt .framer-12izao7 { gap: 0px; } .framer-8qsjt .framer-12izao7 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-8qsjt .framer-12izao7 > :first-child { margin-left: 0px; } .framer-8qsjt .framer-12izao7 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 45
 * @framerIntrinsicWidth 149
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"yN4Iic9l1":{"layout":["auto","auto"]}}}
 * @framerVariables {"bUUso4DP4":"title"}
 */
const Framerq_XzGSjU3: React.ComponentType<Props> = withCSS(Component, css, "framer-8qsjt") as typeof Component;
export default Framerq_XzGSjU3;

Framerq_XzGSjU3.displayName = "Contact button";

Framerq_XzGSjU3.defaultProps = {height: 45, width: 149};

addPropertyControls(Framerq_XzGSjU3, {bUUso4DP4: {defaultValue: "Contact Us", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerq_XzGSjU3, [{family: "Clash Display", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/q_XzGSjU3:default", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/2GQIT54GKQY3JRFTSHS4ARTRNRQISSAA/3CIP5EBHRRHE5FVQU3VFROPUERNDSTDF/JTSL5QESUXATU47LCPUNHZQBDDIWDOSW.woff2"}, style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/2GQIT54GKQY3JRFTSHS4ARTRNRQISSAA/3CIP5EBHRRHE5FVQU3VFROPUERNDSTDF/JTSL5QESUXATU47LCPUNHZQBDDIWDOSW.woff2", weight: "500"}])